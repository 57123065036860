import * as React from 'react';
import Layout from '../compontents/Layout/Layout';

type BlogPageProps = {
  location: any;
};

const BlogPage: React.FC<BlogPageProps> = ({ location }) => {
  return (
    <Layout location={location}>
      <main data-testid="blog-container"></main>
    </Layout>
  );
};

export default BlogPage;
